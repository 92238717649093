@import "../global";

//////// Home Main Banner ////////

.Home-hero-banner {
    @include rem('padding', 80px 0px 30px);
    background: url('/images/home/homepage_hero.png') no-repeat center center;
    background-size: cover;

    @include breakpoint($bp-small) {
        @include rem('padding', 100px 0px 30px);
    }

    @include breakpoint($bp-medium) {
    }

    @include breakpoint($bp-large) {
    }
}

.Home-hero-banner .container {
    @include rem('max-width', 560px);

    @include breakpoint($bp-medium) {
        max-width: none;
    }
}

h1.Home__header {
    @include rem('font-size', 24px);
    @include rem('margin-top', 15px);
    font-weight: 500;
    color: white;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 30px);
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 38px);
    }
}

h2.Home__subheader {
    @include rem('font-size', 16px);
    @include rem('padding', 5px 0px 15px);
    font-weight: 300;
    color: white;
    opacity: 1;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 18px);
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 20px);
    }
}

.Home__description {
    @include rem('font-size', 14px);
    @include rem('margin-bottom', 15px);
    font-weight: 300;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 16px);
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 18px);
    }
}

.Home__compare-plans {
    @extend .btn-style-1;
    @extend .btn-style-green;
    @include rem('margin', 20px 0px 50px);
    @include rem('font-size', 13px);
    @include rem('padding', 14px 40px);
}

.Home__macbook-image {
    @include rem('max-width', 785px);
    width: 80%;
    display: block;
    margin: 0 auto 20px;
}

//////// Home plans ////////

.Home-plans {
    @include rem('padding-top', 50px);
}

.Home-plans h2 {
    @include rem('font-size', 32px);
    font-weight: 400;
    text-align: center;
}

.Home-plans .Plan {
    border: 1px solid #cdd4da;

    @include breakpoint($bp-medium-1) {
        &:not(:nth-child(4)) {
            border-left: none;
        }
    }
}

.Home-plans .Plan__users-pill {
    border-top: 1px solid #cdd4da;
}

//////// Home__features ////////

.Home-features {
    @include rem('padding', 55px 0px);
    background: #f3f5f8;
    text-align: center;
}

.Home-features__header {
    @include rem('font-size', 32px);
    @include rem('margin-bottom', 10px);
    font-weight: 400;
}

.Home-features__subheader {
    @include rem('font-size', 22px);
    @include rem('margin-bottom', 30px);
    font-weight: 300;
    opacity: 0.7;

    @include breakpoint($bp-medium) {
        @include rem('margin-bottom', 50px);
    }
}

.Home-features__feature {
    @include rem('padding', 34px 30px 40px);
    @include rem('max-width', 550px);
    background: white;
    margin: 0 auto 30px;

    h3 {
        @include rem('font-size', 18px);
        @include rem('margin-left', 5px);
        font-weight: 400;
        display: inline;
        vertical-align: middle;
    }

    img {
        vertical-align: middle;
    }

    p {
        @include rem('margin', 25px 0px);
        @include rem('font-size', 15px);
        @include rem('line-height', 24px);
        font-weight: 300;
        opacity: 0.8;
    }

    ul {
        @include rem('margin-bottom', 30px);

        @include breakpoint($bp-large) {
            columns: 2;
        }
    }

    li {
        @include rem('font-size', 15px);
        @include rem('line-height', 28px);
        font-weight: 300;
        opacity: 0.8;

        @include breakpoint($bp-large) {
            text-align: left;
        }
    }

    @include breakpoint($bp-medium) {
        @include span(4 of 8);
    }

    &:nth-child(2n) {
        @include breakpoint($bp-medium) {
            @include span(4 of 8 last);
        }
    }
}

.Home-features__find-button {
    @extend .btn-style-1;
    @include rem('font-size', 15px);
    @include rem('padding', 10px 42px);
}

//////////////// Recent Search Banner ////////////////

.recent-searches-banner {
    background: #f3f5f8;
}

.recent-searches-banner__header {
    @include rem('font-size', 22px);
    text-align: center;
    font-weight: 400;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 32px);
    }
}

.recent-searches-banner__list {
    padding-left: 0;
    list-style-type: none;

    @include rem('margin-top', 24px);

    a {
        text-decoration: none;
        color: #3775d0;
        @include rem('line-height', 32px);
        @include rem('font-size', 17px);
    }

    @include breakpoint($bp-medium) {

        text-align: center;

        a {
            color: $dark-text;
            @include rem('line-height', 30px);
            @include rem('font-size', 19px);
        }

        li {
            display: inline;
            white-space: nowrap;
        }

        li + li {
            &:before {
                content: "|";
                color: #a6b5cd;
                @include rem('margin', 0px 10px);
            }
        }
    }

    @include breakpoint($bp-large) {
        a {
            @include rem('line-height', 34px);
            @include rem('font-size', 22px);
        }
    }
}
